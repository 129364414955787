import React from "react"
import { PageProps } from "gatsby"

import SEO from "../components/seo"

import { GridContainer } from "../components/grid"
import { SecondaryHero } from "../components/heros"
import { SimpleCard } from "../components/cards"
import styled from "styled-components"
import { BaseContainer, VideoContainer } from "../components/containers"

import { StaticImage } from "gatsby-plugin-image"
import PrimaryLink from "../components/links/primary-link"

const IntroCard = styled(SimpleCard)`
  #video-container {
    margin-top: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 3 / span 8;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-column: 3 / span 6;
  }
`

const OurPeopleCard = styled(SimpleCard)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 3 / span 8;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-column: 3 / span 6;
  }
`

const PhotoContainer = styled(BaseContainer)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;
  }
`

const OurPartnersLinkContainer = styled.div`
  display: grid;
  gap: 1rem;
  word-break: break-word;
  margin-top: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 3 / span 8;
    align-items: baseline;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-column: 3 / span 6;
  }
`

const PeopleLinksContainer = styled(BaseContainer)`
  display: grid;
  gap: 1rem;
  word-break: break-word;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    /* grid-template-columns: repeat(2, 1fr); */
    grid-column: 3 / span 8;
    align-items: baseline;
  }
`

const AboutUsPage: React.FC<PageProps> = () => (
  <>
    <SEO
      title="Over Ons"
      description="Wij zijn specialisten in organisatieontwerp, leiderschap, teamontwikkeling en diversiteit &amp; inclusie. Onze manier van werken zorgt ervoor dat je wordt uitgedaagd op intellect, interactie en intuïtie."
    />
    <GridContainer>
      <SecondaryHero
        title="Over ons"
        subtitle="Wij streven ernaar om mensen in organisaties vanuit nieuwsgierigheid te verbinden aan een continue veranderende wereld, door bewustzijn te creëren en betekenis te geven aan het eigen potentieel, en dat van de organisatie."
      ></SecondaryHero>
      <PhotoContainer>
        <StaticImage
          src="../images/over-ons-groepsfoto.jpg"
          alt="Groepsfoto van de leden van De Beukelaar Groep"
          placeholder="blurred"
        />
      </PhotoContainer>
      <IntroCard title="Engaging change">
        <p>
          Wij zijn De Beukelaar Groep, een collectief van vrijdenkers,
          creatievelingen en vakidioten die aan elkaar verbonden zijn door een
          gemeenschappelijke visie op leren en organiseren. Al meer dan 20 jaar
          begeleiden wij organisaties, teams, en professionals met complexe
          vraagstukken. Van organisatieontwerp tot coaching; als je kiest voor
          samenwerken met ons, dan kies je voor een persoonlijke, confronterende
          en integrale aanpak. Dat is wat ons onderscheidt.
        </p>
      </IntroCard>
      <IntroCard title="Wij kijken nèt iets anders">
        <p>
          Onze manier van werken zorgt ervoor dat je wordt uitgedaagd op
          intellect, interactie en intuïtie. De Moderne Sociotechniek biedt
          hiervoor het denkgereedschap om organisaties te begeleiden bij
          veranderingen en herontwerp. Veranderingen gfaat altijd over mensen en
          werkverdeling, en vraagt dus per definitie een integrale aanpak.
        </p>
      </IntroCard>
      <IntroCard title="Kennismaken">
        <p>
          Oprichter en naamgever Edwin de Beukelaar heeft het fundament gelegd
          in 1995. De Beukelaar Groep is in 2019 overgenomen door Mieneke van
          der Wal. Mieneke is van origine relatie- en gezinstherapeut en
          bedrijfskundige. De combinatie van deze twee vakgebieden typeert haar
          werkwijze; grondig en kundig én met oog voor de mens en zijn omgeving.
          Ze heeft veel ervaring in het werken met complexe systemen. Haar
          informele karakter nodigt uit tot het voeren van een echt gesprek.
          Mieneke is dol op complexe vraagstukken en de juiste persoon matchen
          aan een klantvraag.
        </p>
        <VideoContainer
          title="De Beukelaar Groep - Introductie"
          src="https://www.youtube.com/embed/NYXhCYXgtt0?autoplay=0&amp;mute=0"
        ></VideoContainer>
      </IntroCard>
      <OurPeopleCard title="Onze mensen">
        <p>
          De Beukelaar Groep is een collectief van creatievelingen en
          vakidioten. Naast onze vaste partners beschikken we over een breed
          netwerk van verscheidene professionals als trainers, coaches en
          deskundigen die als gast(spreker) worden uitgenodigd om hun expertise
          met ons te delen. Deze werkwijze maakt mogelijk dat we de klantvraag
          te allen tijde centraal kunnen stellen en ons aanbod daarop af kunnen
          stellen.
        </p>
      </OurPeopleCard>
      <PhotoContainer>
        <StaticImage
          src="../images/de-beukelaar-groepsfoto-strand.jpg"
          alt="Groepsfoto van de leden van De Beukelaar Groep aan het strand van Noordwijk"
          placeholder="blurred"
        />
      </PhotoContainer>
      <PeopleLinksContainer>
        <PrimaryLink href="https://www.linkedin.com/in/mienekevanderwal/">
          Mieneke van der wal
        </PrimaryLink>
        <PrimaryLink href="https://www.linkedin.com/in/sabrinavink/">
          Sabrina Vink
        </PrimaryLink>
        <PrimaryLink href="https://www.linkedin.com/in/vincent-sark-182715/">
          Vincent Sark
        </PrimaryLink>
        <PrimaryLink href="https://www.linkedin.com/in/oldenborgh98/">
          Gertjan van Oldenborgh
        </PrimaryLink>
        <PrimaryLink href="https://www.linkedin.com/in/sybren-eikenaar-6b2401/">
          Sybren Eikenaar
        </PrimaryLink>
        <PrimaryLink href="https://www.linkedin.com/in/fleurvanosch/">
          Fleur van Osch
        </PrimaryLink>
        <PrimaryLink href="https://www.linkedin.com/in/dennisfidder/">
          Dennis Fidder
        </PrimaryLink>
        <PrimaryLink href="https://www.linkedin.com/in/robert-tellier-31286aa/">
          Robert Telier
        </PrimaryLink>
        <PrimaryLink href="https://www.linkedin.com/in/edwin-de-beukelaar-4933426/">
          Edwin de beukelaar
        </PrimaryLink>
      </PeopleLinksContainer>
      <IntroCard title="Onze partners">
        <p>
          Wij zijn De Beukelaar Groep en wij geloven in onze kracht en kennis.
          Maar wij geloven ook dat je door samen te werken veel meer kunt
          bereiken en nog veel beter kunt worden. Daarom werken wij samen met
          een aantal partners die ons helpen bij het werk wat we doen in
          organisaties, voor teams en professionals. Bekijk het mooie werk van
          onze partners hier.
        </p>
        <OurPartnersLinkContainer>
          <PrimaryLink href="https://www.tma.nl/">TMA Nederland</PrimaryLink>
          <PrimaryLink href="https://www.globalschoolforentrepreneurship.com/">
            Global School for Entrepreneurship
          </PrimaryLink>
          <PrimaryLink href="https://st-groep.nl/default.html">
            ST Groep
          </PrimaryLink>
          <PrimaryLink href="https://transformationalpresence.org/">
            Center for Transformational Presence
          </PrimaryLink>
          <PrimaryLink href="https://goodworkcompany.nl/">
            Good Work Company
          </PrimaryLink>
        </OurPartnersLinkContainer>
      </IntroCard>
    </GridContainer>
  </>
)

export default AboutUsPage
